import * as _isGraph2 from "graphology-utils/is-graph";

var _isGraph = "default" in _isGraph2 ? _isGraph2.default : _isGraph2;

import * as _defaults2 from "graphology-utils/defaults";

var _defaults = "default" in _defaults2 ? _defaults2.default : _defaults2;

import _iterate from "./iterate.js";
import _helpers from "./helpers.js";
import _defaults3 from "./defaults.js";
var exports = {};

/**
 * Graphology Force Layout
 * ========================
 *
 * A simple force-directed layout algorithm for graphology.
 */
const isGraph = _isGraph;
const resolveDefaults = _defaults;
const iterate = _iterate;
const helpers = _helpers;
const DEFAULTS = _defaults3;
/**
 * Asbtract function used to run the layout for a certain number of iterations.
 *
 * @param  {boolean}       assign       - Whether to assign positions.
 * @param  {Graph}         graph        - Target graph.
 * @param  {object|number} params       - If number, params.maxIterations, else:
 * @param  {number}          maxIterations - Maximum number of iterations.
 * @param  {object}          [settings] - Settings.
 * @return {object|undefined}
 */

function abstractSynchronousLayout(assign, graph, params) {
  if (!isGraph(graph)) throw new Error("graphology-layout-force: the given graph is not a valid graphology instance.");
  if (typeof params === "number") params = {
    maxIterations: params
  };else params = params || {};
  const maxIterations = params.maxIterations;
  params = resolveDefaults(params, DEFAULTS);
  if (typeof maxIterations !== "number" || maxIterations <= 0) throw new Error("graphology-layout-force: you should provide a positive number of maximum iterations."); // Iteration state

  const nodeStates = {};
  let result = null;
  let i; // Iterating

  for (i = 0; i < maxIterations; i++) {
    result = iterate(graph, nodeStates, params);
    if (result.converged) break;
  } // Applying


  if (assign) {
    helpers.assignLayoutChanges(graph, nodeStates, params);
    return;
  }

  return helpers.collectLayoutChanges(nodeStates);
}
/**
 * Exporting.
 */


const synchronousLayout = abstractSynchronousLayout.bind(null, false);
synchronousLayout.assign = abstractSynchronousLayout.bind(null, true);
exports = synchronousLayout;
export default exports;